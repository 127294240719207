.container {
  --_aspect-ratio: 16/9;

  display: flex;
  position: relative;
  border-radius: var(--radius-large);
  width: 100%;
  overflow: hidden;

  @media (width >= 650px) {
    --_aspect-ratio: 1.93;
  }
}

/** 
 * ASPECT RATIO HACK:
 * tried adding aspect ratios to the content and a spacing and had issues.
 * this old skool hack seemed to work the best
 * https://css-tricks.com/aspect-ratio-boxes/
 */
.container::before {
  float: left;
  margin-left: -1px;
  padding-top: calc(100% / (var(--_aspect-ratio)));
  width: 1px;
  height: 0;
  content: "";
}

.container::after {
  display: table;
  clear: both;
  content: ""; /* to clear float */
}

.background-image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    transform: rotate(-180deg);
    opacity: 0.8;
    z-index: 3;
    inset: 0;
    background: linear-gradient(
      180deg,
      #00162b 0%,
      rgba(0, 22, 43, 0.991354) 6.67%,
      rgba(0, 22, 43, 0.964494) 13.33%,
      rgba(0, 22, 43, 0.918348) 20%,
      rgba(0, 22, 43, 0.852603) 26.67%,
      rgba(0, 22, 43, 0.768248) 33.33%,
      rgba(0, 22, 43, 0.668149) 40%,
      rgba(0, 22, 43, 0.557353) 46.67%,
      rgba(0, 22, 43, 0.442747) 53.33%,
      rgba(0, 22, 43, 0.331951) 60%,
      rgba(0, 22, 43, 0.231852) 66.67%,
      rgba(0, 22, 43, 0.147497) 73.33%,
      rgba(0, 22, 43, 0.0817517) 80%,
      rgba(0, 22, 43, 0.0356065) 86.67%,
      rgba(0, 22, 43, 0.00874633) 93.33%,
      rgba(0, 22, 43, 0.0001) 100%
    );
    content: "";
  }
}

@media (width >= 650px) {
  .background-image::after {
    transform: rotate(0deg);
    background: linear-gradient(
      238.37deg,
      rgba(0, 22, 43, 0) 26.01%,
      rgba(0, 22, 43, 0.0086472) 31.2%,
      rgba(0, 22, 43, 0.03551) 36.4%,
      rgba(0, 22, 43, 0.0816599) 41.59%,
      rgba(0, 22, 43, 0.147411) 46.79%,
      rgba(0, 22, 43, 0.231775) 51.98%,
      rgba(0, 22, 43, 0.331884) 57.18%,
      rgba(0, 22, 43, 0.442691) 62.37%,
      rgba(0, 22, 43, 0.557309) 67.57%,
      rgba(0, 22, 43, 0.668116) 72.77%,
      rgba(0, 22, 43, 0.768225) 77.96%,
      rgba(0, 22, 43, 0.852589) 83.16%,
      rgba(0, 22, 43, 0.91834) 88.35%,
      rgba(0, 22, 43, 0.96449) 93.55%,
      rgba(0, 22, 43, 0.991353) 98.74%,
      #00162b 103.94%
    );
  }
}

.background-image-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  position: relative;
  grid-row: 1;
  grid-column: 1;
  flex-grow: 1;
  z-index: 4;
  inset: 0;
  padding: 38px 24px;

  @media (width >= 650px) {
    padding: 53px 44px;
  }
}

.content-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  position: relative;
  margin-top: auto;
  margin-bottom: calc(var(--spacing-bottom) * 0.22);
  max-width: 624px;
  text-align: center;

  @media (width >= 650px) {
    text-align: initial;
  }

  @media (width >= 850px) {
    margin-bottom: calc(var(--spacing-bottom) * 0.34);
  }
}

.title-icon {
  margin-bottom: 11px;
  width: 40px;
  height: auto;
  color: var(--color-primary);

  @media (width >= 850px) {
    margin-bottom: 19px;
    width: 52px;
  }
}

.profile {
  display: flex;
  width: fit-content;
  text-decoration: none;
}

.profile-image {
  margin-right: calc(var(--spacing-sides) * 0.28);
  width: 62px;
  height: 62px;
}

.profile-image-image {
  display: block;
  border-radius: 50%;
}

.profile-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-text-name {
  --cosmos-text-line-height: 1.1;
  --cosmos-text-color: var(--color-text-light);

  @media (width >= 650px) {
    --cosmos-text-font-size: var(--font-size-20px);
  }
}

.profile-text-discipline {
  --cosmos-text-font-size: var(--font-size-16px);
  --cosmos-text-color: var(--color-text-light);

  @media (width >= 650px) {
    --cosmos-text-font-size: var(--font-size-14px);
  }
}
